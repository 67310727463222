@font-face {
  font-family: 'apc';
  font-weight: 700;
  src: url('../fonts/apc-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'apc';
  font-weight: 400;
  src: url('../fonts/apc-regular.woff2') format('woff2');
}

:root {
  --background-color: #040404;
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);

  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-font-smoothing: antialiased;
  cursor: default;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: var(--background-color);
  color: white;
  font-family: 'apc', sans-serif;
  margin: 0;
  transition: background 0.35s var(--ease-in-out-cubic);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

button {
  cursor: pointer;
  font-family: inherit;
  padding: 0;

  &:active,
  &:focus {
    outline: none;
  }
}

svg {
  vertical-align: middle;
}

strong {
  font-weight: 700;
}

code {
  font-family: monospace;
}

::selection {
  background-color: #1cd760;
  color: white;
}
