.readit {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  padding: 18px;

  @include mq($from: desktop) {
    padding: 30px;
  }

  &-media {
    display: flex;
    margin-bottom: 18px;

    @include mq($from: desktop) {
      margin-bottom: 30px;
    }

    &-box {
      background: #c1bdfe;
      border-radius: 3px;
      height: 64px;
      width: 96px;

      @include mq($from: desktop) {
        width: 120px;
      }
    }

    &-lines {
      flex: 1;
      margin-left: 12px;

      @include mq($from: desktop) {
        margin-left: 30px;
      }
    }

    &-headline,
    &-subline {
      background: rgba(black, 0.15);
      border-radius: 3px;
      height: 16px;
    }

    &-headline {
      margin-bottom: 12px;
      width: 100%;

      @include mq($from: desktop) {
        width: 75%;
      }
    }

    &-subline {
      width: 60%;

      @include mq($from: desktop) {
        width: 30%;
      }
    }
  }
}
