.footer {
  padding: 24px 0 60px;

  .common-wrapper {
    position: relative;
  }

  &-link {
    color: white;
    font-size: 18px;
    text-decoration: none;
    transition: opacity 0.35s var(--ease-out-cubic);

    &:hover {
      opacity: 0.75;
    }
  }

  code {
    color: #a1d35b;
    font-weight: bold;
  }
}
